var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Icons" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeIcon) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [_vm._v("Use class ")]),
        _c("code", [_vm._v(".switch-icon-left & .switch-icon-right")]),
        _c("span", [_vm._v(" inside of")]),
        _c("code", [_vm._v("<b-form-checkbox switch>")]),
        _c("span", [_vm._v(" to create a switch with icon.")])
      ]),
      _c("div", { staticClass: "demo-inline-spacing" }, [
        _c(
          "div",
          [
            _c("b-card-text", { staticClass: "mb-0" }, [_vm._v(" Primary ")]),
            _c(
              "b-form-checkbox",
              {
                staticClass: "custom-control-primary",
                attrs: { checked: "true", name: "check-button", switch: "" }
              },
              [
                _c(
                  "span",
                  { staticClass: "switch-icon-left" },
                  [_c("feather-icon", { attrs: { icon: "BellIcon" } })],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "switch-icon-right" },
                  [_c("feather-icon", { attrs: { icon: "BellOffIcon" } })],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c("b-card-text", { staticClass: "mb-0" }, [_vm._v(" Secondary ")]),
            _c(
              "b-form-checkbox",
              {
                staticClass: "custom-control-secondary",
                attrs: { checked: "true", name: "check-button", switch: "" }
              },
              [
                _c(
                  "span",
                  { staticClass: "switch-icon-left" },
                  [_c("feather-icon", { attrs: { icon: "CheckIcon" } })],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "switch-icon-right" },
                  [_c("feather-icon", { attrs: { icon: "XIcon" } })],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c("b-card-text", { staticClass: "mb-0" }, [_vm._v(" Success ")]),
            _c(
              "b-form-checkbox",
              {
                staticClass: "custom-control-success",
                attrs: { name: "check-button", switch: "" }
              },
              [
                _c(
                  "span",
                  { staticClass: "switch-icon-left" },
                  [_c("feather-icon", { attrs: { icon: "Volume2Icon" } })],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "switch-icon-right" },
                  [_c("feather-icon", { attrs: { icon: "VolumeXIcon" } })],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c("b-card-text", { staticClass: "mb-0" }, [_vm._v(" Danger ")]),
            _c(
              "b-form-checkbox",
              {
                staticClass: "custom-control-danger",
                attrs: { name: "check-button", switch: "" }
              },
              [
                _c(
                  "span",
                  { staticClass: "switch-icon-left" },
                  [_c("feather-icon", { attrs: { icon: "CameraIcon" } })],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "switch-icon-right" },
                  [_c("feather-icon", { attrs: { icon: "CameraOffIcon" } })],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c("b-card-text", { staticClass: "mb-0" }, [_vm._v(" Warning ")]),
            _c(
              "b-form-checkbox",
              {
                staticClass: "custom-control-warning",
                attrs: { name: "check-button", switch: "" }
              },
              [
                _c(
                  "span",
                  { staticClass: "switch-icon-left" },
                  [_c("feather-icon", { attrs: { icon: "MicIcon" } })],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "switch-icon-right" },
                  [_c("feather-icon", { attrs: { icon: "MicOffIcon" } })],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c("b-card-text", { staticClass: "mb-0" }, [_vm._v(" Info ")]),
            _c(
              "b-form-checkbox",
              {
                staticClass: "custom-control-info",
                attrs: { name: "check-button", switch: "" }
              },
              [
                _c(
                  "span",
                  { staticClass: "switch-icon-left" },
                  [_c("feather-icon", { attrs: { icon: "WifiIcon" } })],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "switch-icon-right" },
                  [_c("feather-icon", { attrs: { icon: "WifiOffIcon" } })],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c("b-card-text", { staticClass: "mb-0" }, [_vm._v(" Dark ")]),
            _c(
              "b-form-checkbox",
              {
                staticClass: "custom-control-dark",
                attrs: { checked: "true", name: "check-button", switch: "" }
              },
              [
                _c(
                  "span",
                  { staticClass: "switch-icon-left" },
                  [_c("feather-icon", { attrs: { icon: "GithubIcon" } })],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "switch-icon-right" },
                  [_c("feather-icon", { attrs: { icon: "GitlabIcon" } })],
                  1
                )
              ]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }