var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Basic Switch" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeBasic) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [
          _vm._v(
            "A single checkbox can be rendered with a switch appearance by setting the prop "
          )
        ]),
        _c("code", [_vm._v("switch")]),
        _c("span", [_vm._v(" to ")]),
        _c("code", [_vm._v("true")])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-form-checkbox",
            {
              attrs: {
                checked: "true",
                name: "check-button",
                switch: "",
                inline: ""
              }
            },
            [_vm._v(" Active Switch ")]
          ),
          _c(
            "b-form-checkbox",
            {
              attrs: {
                checked: "false",
                name: "check-button",
                switch: "",
                inline: ""
              }
            },
            [_vm._v(" Inactive Switch ")]
          ),
          _c(
            "b-form-checkbox",
            {
              attrs: {
                checked: "true",
                name: "check-button",
                switch: "",
                inline: "",
                disabled: ""
              }
            },
            [_vm._v(" Disabled Active Switch ")]
          ),
          _c(
            "b-form-checkbox",
            {
              attrs: {
                checked: "false",
                name: "check-button",
                switch: "",
                inline: "",
                disabled: ""
              }
            },
            [_vm._v(" Disabled Inactive Switch ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }