var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Grouped switch " },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeGroupArray) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v(
            "Render groups of checkboxes with the look of a switches by setting the prop switches on "
          )
        ]),
        _c("code", [_vm._v("<b-form-checkbox-group>")]),
        _vm._v(". ")
      ]),
      _c(
        "div",
        [
          _c(
            "b-form-group",
            { attrs: { label: "Inline switch style checkboxes" } },
            [
              _c("b-form-checkbox-group", {
                staticClass: "demo-inline-spacing",
                attrs: { options: _vm.options, switches: "" },
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              })
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { label: "Stacked (vertical) switch style checkboxes" } },
            [
              _c("b-form-checkbox-group", {
                attrs: { options: _vm.options, switches: "", stacked: "" },
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("b-card-text", { staticClass: "mb-0" }, [
        _vm._v(" " + _vm._s(_vm.selected) + " ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }