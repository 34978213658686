var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Colors" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeColor) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [_vm._v("Use class ")]),
        _c("code", [_vm._v(".custom-control-#{$color-name}")]),
        _c("span", [_vm._v(" with ")]),
        _c("code", [_vm._v("<b-form-checkbox switch>")]),
        _c("span", [_vm._v(" to change switch's color ")])
      ]),
      _c("div", { staticClass: "demo-inline-spacing" }, [
        _c(
          "div",
          [
            _c("b-card-text", { staticClass: "mb-0" }, [_vm._v(" Primary ")]),
            _c("b-form-checkbox", {
              staticClass: "custom-control-primary",
              attrs: { checked: "true", name: "check-button", switch: "" }
            })
          ],
          1
        ),
        _c(
          "div",
          [
            _c("b-card-text", { staticClass: "mb-0" }, [_vm._v(" Secondary ")]),
            _c("b-form-checkbox", {
              staticClass: "custom-control-secondary",
              attrs: { checked: "true", name: "check-button", switch: "" }
            })
          ],
          1
        ),
        _c(
          "div",
          [
            _c("b-card-text", { staticClass: "mb-0" }, [_vm._v(" Success ")]),
            _c("b-form-checkbox", {
              staticClass: "custom-control-success",
              attrs: { checked: "true", name: "check-button", switch: "" }
            })
          ],
          1
        ),
        _c(
          "div",
          [
            _c("b-card-text", { staticClass: "mb-0" }, [_vm._v(" Danger ")]),
            _c("b-form-checkbox", {
              staticClass: "custom-control-danger",
              attrs: { checked: "true", name: "check-button", switch: "" }
            })
          ],
          1
        ),
        _c(
          "div",
          [
            _c("b-card-text", { staticClass: "mb-0" }, [_vm._v(" Warning ")]),
            _c("b-form-checkbox", {
              staticClass: "custom-control-warning",
              attrs: { checked: "true", name: "check-button", switch: "" }
            })
          ],
          1
        ),
        _c(
          "div",
          [
            _c("b-card-text", { staticClass: "mb-0" }, [_vm._v(" Info ")]),
            _c("b-form-checkbox", {
              staticClass: "custom-control-info",
              attrs: { checked: "true", name: "check-button", switch: "" }
            })
          ],
          1
        ),
        _c(
          "div",
          [
            _c("b-card-text", { staticClass: "mb-0" }, [_vm._v(" Dark ")]),
            _c("b-form-checkbox", {
              staticClass: "custom-control-dark",
              attrs: { checked: "true", name: "check-button", switch: "" }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }